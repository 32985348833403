import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Card from '../../../components/Card';
import { Button } from 'primereact/button';
import { postApiCall } from '../../../service/postApiCall';
import { AGENT_GROUP_CREATE, API_AGENT_UPDATE } from '../../../service/url';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import exportData from '../../../utilities/export';
import { useLocation } from 'react-router-dom';
import ToastNotification from '../../../components/Toast';


export default function CreateAgent() {
   const navigate = useNavigate();
   const location = useLocation();
   const agentData = location.state; 
   const isEditMode = Boolean(agentData);
   const [groupName, setGroupName] = useState('');
   const [agentName, setAgentName] = useState(agentData?.agent_name || '');
   const [agentNumber, setAgentNumber] = useState(agentData?.agent_number || '');
   const [agents, setAgents] = useState([]);
   const [buttonDisable, setButtonDisable] = useState(false);
   const [showToast, setShowToast] = useState(false);
   const [toastProps, setToastProps] = useState({ type: 'success', message: '' });


   const handleSaveAgent = async () => {
      const payload = {
          agent_name: agentName,
          agent_number: String(agentNumber), // Ensure agent_number is sent as a string
      };
  
      try {
          if (isEditMode) {
              // Update existing agent
              await postApiCall(`${API_AGENT_UPDATE}/${agentData._id}`, payload);
              setToastProps({ type: 'success', message: 'Agent updated successfully.' });
              setShowToast(true);
  
              // Navigate after toast disappears (3 seconds delay)
              setTimeout(() => navigate('/agents'), 2000);
          } else {
              // Create a new agent
              await postApiCall(AGENT_GROUP_CREATE, payload);
              setToastProps({ type: 'success', message: 'Agent created successfully.' });
              setShowToast(true);
  
              // Navigate after toast disappears (3 seconds delay)
              setTimeout(() => navigate('/agents'), 3000);
          }
      } catch (error) {
          const errorMessage =
              error?.response?.data?.message || error.message || String(error);
          console.log("ERROR HERE J:", errorMessage);
          setToastProps({ type: 'danger', message: errorMessage });
          setShowToast(true);
          console.error("Error saving agent:", error);
      }
  };
  

   const handleAddAgent = () => {
      if (agentName && agentNumber) {
         const newAgent = { name: agentName, number: agentNumber };
         setAgents([...agents, newAgent]);
         setAgentName('');
         setAgentNumber('');
      }
   };

   const handleDeleteAgent = (index) => {
      const updatedAgents = agents.filter((_, i) => i !== index);
      setAgents(updatedAgents);
   };

   const handleExport = async () => {
      setButtonDisable(true);
      try {
         await exportData('Agents');
         setToastProps({ message: "Data exported successfully.", type: "success" });
      } catch (error) {

         setToastProps({ message: "Failed to export data.", type: "danger" });
      }
      setShowToast(true);
      setButtonDisable(false);
   };

   return (
      <>
         <div style={{ marginTop: "20px" }}>
            <Row>
               <Col sm="12">
                  <Card>
                     <Card.Header className="d-block justify-content-between">
                        <div className="header-title">
                           <h4 className="card-title">{isEditMode ? "Edit Agent" : "Create Agent"}</h4>
                        </div>
                     </Card.Header>
                     <Card.Body>
                        <form>
                           <Row className="mb-3">
                              <Col md="4">
                                 <label htmlFor="agentName" className="form-label">Agent Name</label>
                                 <input
                                    type="text"
                                    id="agentName"
                                    value={agentName}
                                    onChange={(e) => setAgentName(e.target.value)}
                                    className="form-control"
                                    placeholder="Agent Name"
                                    required
                                    disabled={isEditMode} // Disable input in edit mode
                                 />
                              </Col>
                              <Col md="4">
                                 <label htmlFor="agentNumber" className="form-label">Agent Number</label>
                                 <div className="d-flex">
                                    <input
                                       type="number"
                                       id="agentNumber"
                                       value={agentNumber}
                                       onChange={(e) => setAgentNumber(e.target.value)}
                                       className="form-control"
                                       placeholder="Agent Number"
                                       required
                                    />
                                 </div>
                              </Col>
                           </Row>
                           <Button
                              label={isEditMode ? "Update" : "Create"}
                              onClick={handleSaveAgent}
                              className="p-button-danger mt-3"
                              type="button"
                           />
                        </form>
                     </Card.Body>
                  </Card>
               </Col>
            </Row>
         </div>
         
         {/* Render the toast notification */}
         <ToastNotification show_toast={showToast} set_show_toast={setShowToast} {...toastProps} />
      </>
   );
   
}
