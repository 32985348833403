export const BACKEND_API_BASE_URL =
  process.env.NODE_ENV == "production"
    ? "https://voiceboxapi.nspl.cloud/api"
    : "https://voiceboxapi.nspl.cloud/api";

export const USER_STATUS = [
  { value: 1, label: "Active" },
  { value: 0, label: "Inactive" },
];

export const USER_STATUS_LABEL = ["Active", "Inactive"];

export const USER_ROLES = {
  RESELLER: "role_reseller",
  SUPERADMIN: "role_sadmin",
  COMPANY_ADMIN: "role_cadmin",
  CALL_CENTRE_ADMIN: "role_ccadmin",
  CALL_CENTRE_TEAM_LEAD: "role_ccteamlead",
  CALL_CENTRE_AGENT: "role_ccagent",
  CALL_CENTRE: "role_call_centre",
  SUB_SUPERADMIN: "role_sub_superadmin"
};

export const USER_ROLES_MAP = {
  [USER_ROLES.RESELLER]: "Reseller",
  [USER_ROLES.SUPERADMIN]: "Superadmin",
  [USER_ROLES.COMPANY_ADMIN]: "Company user",
  [USER_ROLES.CALL_CENTRE_ADMIN]: "Call Centre Admin User",
  [USER_ROLES.CALL_CENTRE_TEAM_LEAD]: "Call Centre Admin Team Lead",
  [USER_ROLES.CALL_CENTRE_AGENT]: "Call Centre Admin Agent",
  [USER_ROLES.CALL_CENTRE]: "Call Centre",
  [USER_ROLES.SUB_SUPERADMIN]: "Sub Superadmin"
};

export const PERMISSION_TYPES = {
  CREATE: "permission_create",
  READ: "permission_read",
  UPDATE: "permission_update",
  DELETE: "permission_delete",
};

export const ROUTES = {
  LOGIN: "auth/sign-in",
  DASHBOARD: "dashboard",
  SWITCH_ACCOUNT_LOADER: "/switch-account-loader",
  USERS: {
    EDIT: "users/:id",
    CREATE: "users/create",
    LIST: "users",
    RESELLERALLOCATE:'allocate/:id/:role',
  },
  COMPANIES: {
    LIST: "companies",
    EDIT: "companies/:id",
    CREATE: "companies/create",
  },
  CALL_CENTRES: {
    LIST: "call-centres",
    CREATE: "call-centres/create",
    EDIT: "call-centres/:id",
    USERS: {
      LIST: "call-centres/:id/users",
      CREATE: "call-centres/:id/users/create",
      EDIT: "call-centres/:id/users/:uid",
    },
  },
  TRUNKS: {
    EDIT: "trunks/:id",
    CREATE: "trunks/create",
    LIST: "trunks",
    DELETE: "trunks/:id",
  },
  NUMBERS: {
    EDIT: "numbers/:id",
    CREATE: "numbers/create",
    LIST: "numbers",
    DELETE: "numbers/:id",
    NUMBER_FILE_LIST: 'number-filelist/',
    NUMBER_FILE_DELETE: 'number-filelist/delete',
    STATUS_CHANGE_ACTION: 'number-status-change/',
    DID_MAPPING:'numbers/did-mapping',
    ALLOCATED_NUMBERS:'numbers/allocated/',

  },
  IVR: {
    EDIT: "call-centres/:cid/ivr/:id",
    CREATE: "call-centres/:cid/ivr/create",
    LIST: "call-centres/:cid/ivr",
    DELETE: "call-centres/:cid/ivr/:id",
  },
  DATA_CENTER: {
    EDIT: "data-center/:id",
    CREATE: "data-center/create",
    LIST: "data-center",
    DELETE: "data-center",
  },
  SERVER_MANAGEMENT: {
    EDIT: "server-management/:id",
    CREATE: "server-management/create",
    LIST: "server-management",
    DELETE: "server-management",
  },
  MODULE: {
    EDIT: "module/:id",
    CREATE: "module/create",
    LIST: "module",
    DELETE: "module/:id",
  },
  ACL_SETTING: {
    CREATE: "acl-settings/create",
    LIST: "acl-settings",
    EDIT: "acl-settings/:id",
    DELETE: "acl-settings/:id"
  },
  JOURNEY_OPERATION:{
    LIST: "journey-operation",
  },
  MANAGE_AGENTS: {
    AGENTS:"agents",
    CREATE_AGENT:"create-agent",
    CREATE_AGENT_GROUP:"create-agent-group",
    AGENT_GROUP_LIST:"agent-group-list",
    EXTENTION:"extention",
    CREATE_EXTENTION:"create-extention"
  },
};

export const TRUNK_AUTH_TYPE_REGISTRATION = 1;
export const TRUNK_AUTH_TYPE_IP = 2;
export const TRUNK_AUTH_TYPES = [
  { label: "IP", value: TRUNK_AUTH_TYPE_IP },
  { label: "Registration", value: TRUNK_AUTH_TYPE_REGISTRATION },
];

export const OPERATOR_TYPES = [
  { label: "Airtel", value: 1 },
  { label: "Jio", value: 2 },
];

export const CONTENT_STATUS = [
  { label: "Active", value: 1 },
  { label: "Inactive", value: 0 },
];

export const NUMBERS_FORMAT_INDIVIDUAL = "INDIVIDUAL";
export const NUMBERS_FORMAT_FILE = "FILE";
export const NUMBERS_FORMAT_TYPES = [
  { label: "INDIVIDUAL", value: NUMBERS_FORMAT_INDIVIDUAL},
  { label: "FILE", value: NUMBERS_FORMAT_FILE },
];

export const NUMBERS_TYPES_DID ="DID";
export const NUMBERS_TYPES_VMN = "VMN";
export const NUMBERS_TYPES_TOLFREE ="TOLL FREE";
export const NUMBERS_TYPES =[
  {label: "DID", value: NUMBERS_TYPES_DID},
  {label: "VMN", value: NUMBERS_TYPES_VMN},
  {label: "TOLL FREE", value: NUMBERS_TYPES_TOLFREE},
]
export const BULK_UPDATE_DID = ['DID', 'Category', 'Currency', 'Country Code', 'State Code', 'Cost', 'Operator', 'Status'];
export const BULK_UPDATE_OTHERS = ['DID', 'Currency', 'Country Code', 'Cost', 'Operator', 'Status'];
export const UPLOAD_NUMBER_DID = ['Country Code','State Code','DID','Cost','Operator'];
export const UPLOAD_NUMBER_OTHERS= ['Country Code','DID','Cost','Operator'];
export const ASSIGN_DID_VMN = ['DID','STATUS' , 'VMN'];
export const ASSIGN_DID_TOLLFREE = ['DID','STATUS' , 'TOLL FREE'];



export const NUBMERS_STATUS_LABEL =['Available','Blocked', 'Reserved', 'Archived', 'Terminated', 'Demo', 'Live', 'Delete', 'Pending', 'Reject']
export const API_DID_NUMBERS = 'numbers/did';


export const API_NUMBER_STATUS = 'numbers/number-status';


export const DATA_CENTER_DOMESTIC_TYPE = 0;
export const DATA_CENTER_INTERNATIONAL = 1;
export const DATA_CENTER_TYPES = [
  { label: "Domestic", value: DATA_CENTER_DOMESTIC_TYPE },
  { label: "International", value: DATA_CENTER_INTERNATIONAL },
];
export const DOMESTIC_COUNTRY = { label: "IN", value: "India" };

export const SERVER_MANAGEMENT_DOMESTIC_TYPE = 0;
export const SERVER_MANAGEMENT_INTERNATIONAL = 1;
export const SERVER_TYPES = [
  { label: "Domestic", value: SERVER_MANAGEMENT_DOMESTIC_TYPE },
  { label: "International", value: SERVER_MANAGEMENT_INTERNATIONAL },
];

export const STATUS = [
  { value: 1, label: "Active" },
  { value: 0, label: "Inactive" },
];

export const USERS_ROLE = {
  role_sadmin: "Super Admin",
  role_reseller: "Reseller",
  role_cadmin: "Company User",
  role_ccadmin: "Callcentre Admin",
  role_ccteamlead: "Callcenter Team Lead",
  role_ccagent: "Callcentre Agent",
  role_sub_superadmin: "Sub SuperAdmin"
};
