import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Card from '../../../components/Card';
import { Button } from 'primereact/button';
import { API_AGENTS_LIST, API_AGENT_GROUPS_LIST, API_AGENT_GROUP_EXTENSION } from '../../../service/url';
import { getApiCall } from '../../../service/getApiCall';
import { postApiCall } from '../../../service/postApiCall';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import ToastNotification from '../../../components/Toast';

export default function CreateAgentGroups() {
    const navigate = useNavigate();
    const [groupName, setGroupName] = useState('');
    const [selectedAgent, setSelectedAgent] = useState(null);
    const [selectedExtension, setSelectedExtension] = useState(null);
    const [agents, setAgents] = useState([]);
    const [agentOptions, setAgentOptions] = useState([]);
    const [extensionOptions, setExtensionOptions] = useState([]);
    const [showToast, setShowToast] = useState(false);
    const [toastProps, setToastProps] = useState({ type: 'success', message: '' });

    useEffect(() => {
        getApiCall(API_AGENTS_LIST)
            .then((data) => {
                const options = data.map((agent) => ({
                    label: agent.agent_name,
                    value: agent,
                }));
                setAgentOptions(options);
            })
            .catch((error) => {
                console.error('Error fetching agents:', error);
                setToastProps({ type: 'danger', message: 'Error fetching agents.' });
                setShowToast(true);
            });
    }, []);

    useEffect(() => {
        getApiCall(API_AGENT_GROUP_EXTENSION)
            .then((data) => {
                const options = data.map((extension) => ({
                    label: extension.extention_name,
                    value: extension._id, // Assuming `extension._id` is the unique ID for each extension
                }));
                setExtensionOptions(options);
            })
            .catch((error) => {
                console.error('Error fetching extensions:', error);
                setToastProps({ type: 'danger', message: 'Error fetching extensions.' });
                setShowToast(true);
            });
    }, []);

    const handleAddAgent = () => {
        if (selectedAgent) {
            const newAgent = {
                name: selectedAgent.agent_name,
                number: selectedAgent.agent_number,
                id: selectedAgent.id || selectedAgent._id,
            };
            setAgents([...agents, newAgent]);
            setSelectedAgent(null);
        }
    };

    const handleCreateGroup = () => {
        const agentIds = agents.map((agent) => agent.id);
        const data = {
            group_name: groupName,
            agent_id: agentIds,
            extention: selectedExtension ? [selectedExtension] : [], // Send extension as an array
        };

        postApiCall(API_AGENT_GROUPS_LIST, data)
            .then((response) => {
                setToastProps({ type: 'success', message: 'Agent group created successfully.' });
                setShowToast(true);
                // Navigate after toast disappears (3 seconds delay)
                setTimeout(() => navigate('/agent-group-list'), 3000);
            })
            .catch((error) => {
                const errorMessage =
                    error?.response?.data?.message ||
                    error.message ||
                    'Error creating agent group.';
                console.error('Error creating agent group:', error);
                setToastProps({ type: 'danger', message: errorMessage });
                setShowToast(true);
            });
    };

    const handleDeleteAgent = (index) => {
        const updatedAgents = agents.filter((_, i) => i !== index);
        setAgents(updatedAgents);
    };

    return (
        <>
            <div style={{ marginTop: '20px' }}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <Card.Header className="d-block justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Create Agent Group</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <form>
                                    <Row className="mb-3">
                                        <Col md="4">
                                            <label htmlFor="groupName" className="form-label">
                                                Group Name
                                            </label>
                                            <input
                                                type="text"
                                                id="groupName"
                                                value={groupName}
                                                onChange={(e) => setGroupName(e.target.value)}
                                                className="form-control"
                                                placeholder="Group Name"
                                                required
                                            />
                                        </Col>
                                        <Col md="4">
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    width: '50%',
                                                    gap: '20px',
                                                }}
                                            >
                                                <Dropdown
                                                    id="agentDropdown"
                                                    value={selectedAgent}
                                                    options={agentOptions}
                                                    onChange={(e) => setSelectedAgent(e.value)}
                                                    placeholder="Select Agent"
                                                    className="p-column-filter"
                                                    style={{ marginTop: '25px' }}
                                                />
                                                <Dropdown
                                                    id="extensionDropdown"
                                                    value={selectedExtension}
                                                    options={extensionOptions}
                                                    onChange={(e) => setSelectedExtension(e.value)}
                                                    placeholder="Select Extension"
                                                    className="p-column-filter"
                                                    style={{ marginTop: '25px' }}
                                                />
                                            </div>
                                        </Col>
                                        <Col md="3">
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                {selectedAgent && (
                                                    <>
                                                        <input
                                                            style={{
                                                                marginTop: '30px',
                                                                cursor: 'not-allowed',
                                                            }}
                                                            type="text"
                                                            id="agentNumber"
                                                            value={
                                                                selectedAgent
                                                                    ? selectedAgent.agent_number
                                                                    : ''
                                                            }
                                                            className="form-control"
                                                            placeholder="Agent Number"
                                                            readOnly
                                                        />
                                                        <Button
                                                            style={{
                                                                marginLeft: '10px',
                                                                height: '50%',
                                                                marginTop: '28px',
                                                            }}
                                                            icon="pi pi-plus"
                                                            className="fa fa-plus-circle plus-icon"
                                                            onClick={handleAddAgent}
                                                            disabled={!selectedAgent}
                                                        />
                                                    </>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                </form>

                                {agents.length > 0 && (
                                    <>
                                        <div className="mt-4">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Agent Number</th>
                                                        <th>Agent Name</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {agents.map((agent, index) => (
                                                        <tr key={index}>
                                                            <td>{agent.number}</td>
                                                            <td>{agent.name}</td>
                                                            <td>
                                                                <Button
                                                                    icon="pi pi-minus"
                                                                    className="fa fa-minus-circle plus-icon"
                                                                    onClick={() =>
                                                                        handleDeleteAgent(index)
                                                                    }
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>

                                        <Button
                                            label="Create"
                                            onClick={handleCreateGroup}
                                            className="p-button-danger mt-3"
                                            type="button"
                                        />
                                    </>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>

            {/* Render Toast Notification */}
            <ToastNotification
                show_toast={showToast}
                set_show_toast={setShowToast}
                {...toastProps}
            />
        </>
    );
}
