import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Card from '../../../components/Card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import RenderDeleteModal from '../../../components/partials/components/delete-modal';
import ToastNotification from '../../../components/Toast';
import Loader from '../../../components/partials/components/loader';
import { API_AGENTS_LIST, API_AGENT_DELETE } from '../../../service/url';
import { deleteApiCall, getApiCall, postApiCall } from '../../../service/httpCall';
import exportData from '../../../utilities/export';
import { ROUTES } from '../../../utilities/constant';
import '../../../assets/custom/css/custom.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';

const Agents = () => {
   const navigate = useNavigate();
   const [agentList, setAgentList] = useState([]);
   const [loading, setLoading] = useState(false);
   const [enableDeleteModal, setEnableDeleteModal] = useState(false);
   const [confirmDelete, setConfirmDelete] = useState(false);
   const [selectedAgents, setSelectedAgents] = useState([]); // New state for selected agents
   const [showToast, setShowToast] = useState(false);
   const [toastProps, setToastProps] = useState(null);
   const [buttonDisable, setButtonDisable] = useState(false);

   // Automatically hide toast after 3 seconds
   useEffect(() => {
      if (showToast) {
         const timer = setTimeout(() => {
            setShowToast(false);
         }, 3000);
         return () => clearTimeout(timer);
      }
   }, [showToast]);

   useEffect(() => {
      if (confirmDelete) {
         handleDeleteSelectedAgents();
      }
   }, [confirmDelete]);

   // Fetch Agents Data
   const fetchAgents = () => {
      setLoading(true);
      getApiCall(API_AGENTS_LIST)
         .then((resp) => {
            if (resp) {
               const updatedList = resp.map((val, idx) => ({
                  ...val,
                  key: idx + 1,
                  createdAt: new Date(val.createdAt).toLocaleString(),
               }));
               setAgentList(updatedList);
            } else {
               setToastProps({ type: 'danger', message: 'Failed to fetch agents' });
               setShowToast(true);
            }
         })
         .catch((error) => {
            setToastProps({ type: 'danger', message: 'Error fetching agents' });
            setShowToast(true);
         })
         .finally(() => setLoading(false));
   };

   useEffect(fetchAgents, []);

   const handleDeleteSelectedAgents = () => {
      console.log("CALLED DETELE");
      if (selectedAgents.length === 0) return;
      postApiCall(API_AGENT_DELETE, { agentIds: selectedAgents })
         .then(() => {
            setToastProps({ type: 'success', message: 'Agents deleted successfully' });
            setShowToast(true);
            fetchAgents();
            setSelectedAgents([]); // Clear selected agents after deletion
         })
         .catch((err) => {
            setToastProps({ type: 'danger', message: `Unable to delete agents: ${err}` });
            setShowToast(true);
         })
         .finally(() => setEnableDeleteModal(false));
   };

   const handleExport = async () => {
      setButtonDisable(true);
      await exportData('Agent', setToastProps, setShowToast);
      setButtonDisable(false);
   };

   const onCheckboxChange = (e, agentId) => {
      const { checked } = e.target;
      setSelectedAgents((prevSelected) =>
         checked ? [...prevSelected, agentId] : prevSelected.filter((id) => id !== agentId)
      );
   };

   const onHeaderCheckboxChange = (e) => {
      const { checked } = e.target;
      if (checked) {
         const allAgentIds = agentList.map((agent) => agent._id);
         setSelectedAgents(allAgentIds);
      } else {
         setSelectedAgents([]);
      }
   };

   const handleEditAgent = (agent) => {
      // Navigate to CreateAgent page with agent data as state
      navigate('/create-agent', { state: agent });
   };
   

   return (
      <div style={{ marginTop: '20px' }}>
         <Row>
            <Col sm="12">
               <Card>
                  <Card.Header className="d-flex justify-content-between">
                     <h4>Agents List</h4>
                     <Button className="btn btn-primary" onClick={() => navigate('/create-agent')}>Create Agent</Button>
                  </Card.Header>
                  <Card.Body className="px-0">
                     {loading ? <Loader /> : (
                        <>
                           <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '10px' }}>
                              <Button
                                 label="Delete Selected"
                                 onClick={() => setEnableDeleteModal(true)}
                                 className="p-button-danger p-button-sm"
                                 style={{ marginLeft: '10px' }}
                                 disabled={selectedAgents.length === 0} // Disable if no agents selected
                              />
                              <Button
                                 label="Export Data"
                                 onClick={handleExport}
                                 className="p-button-active p-button-sm"
                                 style={{ marginLeft: '10px' }}
                                 disabled={buttonDisable}
                              >
                                 {buttonDisable && (
                                    <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>
                                 )}
                              </Button>
                           </div>

                           <DataTable value={agentList} paginator rows={10}>
                              <Column
                                 header={<Checkbox onChange={onHeaderCheckboxChange} checked={selectedAgents.length === agentList.length && agentList.length > 0} />}
                                 body={(rowData) => (
                                    <Checkbox onChange={(e) => onCheckboxChange(e, rowData._id)} checked={selectedAgents.includes(rowData._id)} />
                                 )}
                                 style={{ width: '3rem' }}
                              />
                              <Column field="agent_name" header="Agent Name" />
                              <Column field="agent_number" header="Agent Number" />
                              <Column field="createdBy" header="Created By" />
                              <Column field="createdAt" header="Joining Date" />
                              <Column
   body={(rowData) => (
      <div className="actions">
         <Button
         style={{backgroundColor:"#f16a1b", marginRight:"10px", border:"#f16a1b"}} 
            className="btn btn-sm btn-icon btn-warning"
            onClick={() => handleEditAgent(rowData)}
            title="Edit"
         >
            <span className="btn-inner" style={{backgroundColor:"#f16a1b"}}>
               <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                  <path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                  <path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
               </svg>
            </span>
         </Button>
         <Button
         style={{backgroundColor:"#c03221", border:"#c03221"}}
            className="btn btn-sm btn-icon btn-danger"
            onClick={() => handleDeleteSelectedAgents([rowData._id])}
            title="Delete"
         >
            <span className="btn-inner">
               <svg style={{backgroundColor:"#c03221"}} width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                  <path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                  <path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
               </svg>
            </span>
         </Button>
      </div>
   )}
/>

                           </DataTable>
                        </>
                     )}
                  </Card.Body>
               </Card>
            </Col>
         </Row>

         <RenderDeleteModal
            show={enableDeleteModal}
            setShow={setEnableDeleteModal}
            setConfirmDelete={setConfirmDelete}
            isMultiDelete={true}
            clearSelectedData={() => setSelectedAgents([])}
            onConfirm={handleDeleteSelectedAgents} // Pass the handler for multiple delete
         />

         {/* <ToastNotification show={showToast} set_show_toast={setShowToast} {...toastProps} /> */}
      </div>
   );
};

export default Agents;
