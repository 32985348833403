import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Card from '../../../components/Card';
import { Button } from 'primereact/button';
import { postApiCall } from '../../../service/postApiCall';
import { API_EXTENTION } from '../../../service/url';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify styles
import { getUser } from '../../../utilities/setting';

const CreateExtention = () => {
   const navigate = useNavigate();
   const [extensionName, setExtensionName] = useState('');
   const [prefix, setPrefix] = useState('');
   const [buttonDisable, setButtonDisable] = useState(false);
   const [rolePrefix, setRolePrefix] = useState('');
   const [prefixError, setPrefixError] = useState(''); // State for validation error

   useEffect(() => {
      getUser()
         .then((data) => setRolePrefix(String(data.prefix)))
         .catch((error) => {
            console.error('Error fetching user data:', error);
            toast.error('Failed to fetch user data.');
         });
   }, []);

   const handleSaveExtension = async () => {
      if (prefixError) {
         toast.error('Please fix the errors before submitting.');
         return;
      }

      const payload = {
         extention_name: extensionName,
         prefix: `${rolePrefix}${prefix}`, // Combine rolePrefix and prefix as a string
      };

      try {
         setButtonDisable(true); // Disable button during API call
         await postApiCall(API_EXTENTION, payload);
         toast.success('Extension created successfully.');
         setTimeout(() => navigate('/extensions'), 3000); // Navigate after 3 seconds
      } catch (error) {
         console.error('Error creating extension:', error);
         const errorMessage =
            error?.response?.data?.message || 'Failed to create extension.';
         toast.error(errorMessage);
      } finally {
         setButtonDisable(false); // Re-enable button after API call
      }
   };

   const handlePrefixChange = (e) => {
      const input = e.target.value;

      // Check if the input is a number and within the range 100 to 999
      if (!input || (Number(input) >= 100 && Number(input) <= 999)) {
         setPrefix(input);
         setPrefixError(''); // Clear error if valid
      } else {
         setPrefix(input);
         setPrefixError('Extension must be a 3-digit number between 100 and 999.');
      }
   };

   return (
      <>
         <div style={{ marginTop: '20px' }}>
            <Row>
               <Col sm="12">
                  <Card>
                     <Card.Header className="d-block justify-content-between">
                        <div className="header-title">
                           <h4 className="card-title">Create Extension</h4>
                        </div>
                     </Card.Header>
                     <Card.Body>
                        <form>
                           <Row className="mb-3">
                              <Col md="4">
                                 <label htmlFor="extensionName" className="form-label">
                                    Extension Name
                                 </label>
                                 <input
                                    type="text"
                                    id="extensionName"
                                    value={extensionName}
                                    onChange={(e) => setExtensionName(e.target.value)}
                                    className="form-control"
                                    placeholder="Extension Name"
                                    required
                                 />
                              </Col>
                              <Col md="4">
                                 <label htmlFor="prefix" className="form-label">
                                    Extension
                                 </label>
                                 <input
                                    type="number"
                                    id="prefix"
                                    value={prefix}
                                    onChange={handlePrefixChange}
                                    className={`form-control ${prefixError ? 'is-invalid' : ''}`}
                                    placeholder="Extension"
                                    required
                                 />
                                 {prefixError && (
                                    <div className="invalid-feedback">{prefixError}</div>
                                 )}
                              </Col>
                           </Row>
                           <Button
                              label="Create"
                              onClick={handleSaveExtension}
                              className="p-button-danger mt-3"
                              type="button"
                              disabled={buttonDisable}
                           >
                              {buttonDisable && (
                                 <span
                                    className="spinner-border spinner-border-sm ml-2"
                                    role="status"
                                    aria-hidden="true"
                                 ></span>
                              )}
                           </Button>
                        </form>
                     </Card.Body>
                  </Card>
               </Col>
            </Row>
         </div>
         <ToastContainer position="top-right" autoClose={3000} />
      </>
   );
};

export default CreateExtention;
